import React, { useEffect, useMemo } from 'react';
import { Input, RadioButtons, Section, sectionStyles } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import IndexFormula from '@utils/types/IndexFormula';
import classNames from 'classnames';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { SectionHeader } from '../../components/SectionHeader';
import { CONTRACT_TYPES_WITH_EDF_FORMULAS } from '../../constants/contract-types-with-edf-formulas';
import { AnnualIndexation } from './components/AnnualIndexation';
import { CapaPricing } from './components/CapaPricing';
import { DetailedIndexFormulaPicker, IndexFormulaPicker } from './components/IndexFormula';
import { RefPrice, RefPriceWithRateChange } from './components/RefPrice';

export const Pricing = ({
  title,
  readOnly,
  queries: { indexFormulas },
  form: { control, watch, setValue },
}: ContractFormSectionProps) => {
  const [startDate, endDate, contractType, currency, id, subType] = watch([
    'start_date',
    'end_date',
    'type',
    'currency',
    'id',
    'sub_type',
  ]);

  useEffect(() => {
    setValue('contract_sub_periods_attributes.0.dates', [startDate, endDate]);
  }, [endDate, setValue, startDate]);

  const displayAnnualIndexationData =
    readOnly && contractType && ['ContractCrEdf', 'ContractOa'].includes(contractType);
  const aboveCapPrice = watch('above_cap_price');
  const isAboveCapPriceIndexed = watch('is_indexed');

  const showIndexedPricing = isAboveCapPriceIndexed != null && aboveCapPrice !== null;

  const indexFormulaOptions: Option<IndexFormula['formula']>[] = useMemo(() => {
    if (!indexFormulas.data) return [];
    const { edfFormulas, formulas } = indexFormulas.data;
    const filteredFormulaList = CONTRACT_TYPES_WITH_EDF_FORMULAS.includes(contractType) ? edfFormulas : formulas;

    return [
      ...filteredFormulaList?.map(({ formula }) => ({
        label: formula,
        value: formula,
      })),
    ];
  }, [contractType, indexFormulas.data]);

  const polishIndexFormulasOption = [{ label: 'PL_AUCTION', value: 'PL_AUCTION' }];
  const contractsWithRefPriceAndIndexation = ['ContractCrEdf', 'ContractOa', 'ContractSwapPl'];
  const simpleRefPriceAndIndexation = contractsWithRefPriceAndIndexation.includes(contractType || '') && (
    <div className={sectionStyles.row}>
      <RefPrice control={control} readOnly={readOnly} currency={currency} />
      <IndexFormulaPicker
        control={control}
        options={contractType === 'ContractSwapPl' ? polishIndexFormulasOption : indexFormulaOptions}
        isLoading={indexFormulas.isPending}
        readOnly={readOnly}
        defaultValue={contractType === 'ContractSwapPl' ? 'PL_AUCTION' : undefined}
      />
    </div>
  );

  const birthdayDate = watch('birthday_date');
  const contractsWithDetailedRefPrice = ['ContractOa'];
  const subTypeIsElligibleToManagementBonus = ['cr16', 'cr17'].includes(subType || '');
  const detailedRefPriceAndIndexation = contractsWithDetailedRefPrice.includes(contractType || '') && (
    <>
      <RefPriceWithRateChange
        control={control}
        readOnly={readOnly}
        currency={currency}
        birthdayDate={birthdayDate || null}
      />
      <DetailedIndexFormulaPicker
        control={control}
        options={indexFormulaOptions}
        isLoading={indexFormulas.isPending}
        readOnly={readOnly}
        currency={currency}
        hasManagementBonus={readOnly && subTypeIsElligibleToManagementBonus}
      />
    </>
  );

  const refPriceAndIndextion = detailedRefPriceAndIndexation || simpleRefPriceAndIndexation;

  const contractsWithCapaPricing = ['ContractCapa'];
  const capaPricing = contractsWithCapaPricing.includes(contractType || '') && (
    <CapaPricing control={control} readOnly={readOnly} currency={currency} />
  );

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          {refPriceAndIndextion}
          {capaPricing}
          {readOnly && showIndexedPricing && (
            <div className={classNames(sectionStyles.row, 'flex')}>
              <Input
                label="contracts.capped_price"
                size="lg"
                tooltip="contracts.capped_price_details"
                type="number"
                autoComplete="off"
                readOnly={readOnly}
                full
                value={aboveCapPrice}
                suffix={`${getSymbol(currency)}/MWh`}
              />
              <RadioButtons
                size="lg"
                readOnly
                selected={isAboveCapPriceIndexed}
                label="contracts.capped_price_indexation"
                options={[
                  { label: 'common.yes', value: true },
                  { label: 'common.no', value: false },
                ]}
              />
            </div>
          )}
          {displayAnnualIndexationData && id && <AnnualIndexation contractId={id} currency={currency} />}
        </Section>
      }
    />
  );
};
